<template>
  <v-card class="mt-5 pa-5 pt-0" flat>
    <v-card-title class="px-0 pb-1">
      <span class="text-h5 font-weight-bold">Thiết lập trên website</span>
    </v-card-title>

    <div class="mt-6 px-0 pt-0">
      <span class="text-h6 font-weight-bold">Thông tin chung</span>

      <v-row class="pt-0">
        <v-col cols="12" md="6">
          <form-site-model-available
            v-model="computedModelSiteConfigs"
            :model-id="product.id"
            :model-type="LIST_MODEL_TYPES.PRODUCT"
          ></form-site-model-available>
        </v-col>

        <v-col class="" cols="6">
          <div class="d-flex align-center font-weight-bold mb-2">
            <div>Trạng thái</div>

            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  class="ml-1"
                  color="grey"
                  dark
                  size="14px"
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-information
                </v-icon>
              </template>
              <div>
                Khi chuyển trạng thái của sản phẩm sang
                <span class="font-weight-bold">Chỉ SEO</span> hoặc
                <span class="font-weight-bold">Ẩn</span>,
              </div>

              <div>
                các SKU sẽ tự động chuyển qua
                <span class="font-weight-bold">ngừng kinh doanh</span>
              </div>
            </v-tooltip>
          </div>

          <tp-select
            v-model="product.status"
            :disabled="
              productOptionStatusRequest.value ===
                'loading-updateBusinessStatus'
            "
            :items="PRODUCT_STATUS"
            :loading="
              productOptionStatusRequest.value ===
                'loading-updateBusinessStatus'
            "
            hide-details="auto"
            item-text="label"
            item-value="value"
            placeholder="Chọn trạng thái"
            :background-color="
              product.status === 'HIDDEN' ? 'red lighten-2' : ''
            "
            :item-color="
              product.status === 'HIDDEN' ? 'red darken-1' : 'primary'
            "
            solo
            @change="handleChangeProductStatus"
          >
          </tp-select>
        </v-col>

        <v-col class="" cols="12" md="6">
          <div class="font-weight-bold mb-2">Tình trạng ra mắt</div>
          <v-checkbox
            v-model="product.ttrm"
            class="mt-0"
            dense
            label="Sản phẩm dự đoán"
          ></v-checkbox>
        </v-col>

        <v-col class="py-0" cols="6">
          <div class="font-weight-bold mb-2">Ưu tiên hiển thị</div>

          <v-text-field
            v-model="product.priority"
            background-color="white"
            class="text-body-1"
            dense
            filled
            outlined
            placeholder="Ưu tiên hiển thị"
            single-line
            type="number"
          ></v-text-field>
        </v-col>
      </v-row>
    </div>
  </v-card>
</template>

<script>
import { cloneDeep } from "@thinkvn/utils";

import FormSiteModelAvailable from "@/core/components/form/FormSiteModelAvailable.vue";

import {
  ARRAY_LIST_MODEL_SITES,
  LIST_MODEL_TYPES,
  PRODUCT_STATUS
} from "@/core/constant";

export default {
  components: { FormSiteModelAvailable },

  props: {
    modelSiteConfigs: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      ARRAY_LIST_MODEL_SITES,
      LIST_MODEL_TYPES,
      PRODUCT_STATUS,
      productClone: {}
    };
  },

  mounted() {
    this.productClone = cloneDeep(this.product);
  },

  computed: {
    articles() {
      return this.product.articles.reduce(
        (obj, cur) => ({
          ...obj,
          [cur.site]: cur
        }),
        {}
      );
    },

    computedModelSiteConfigs: {
      get() {
        return this.productModelSiteConfigs;
      },
      set(val) {
        this.$store.dispatch("PRODUCT/setProductModelSiteConfigs", val);
      }
    },

    product() {
      return this.$store.getters["PRODUCT/product"];
    },

    productModelSiteConfigs() {
      return this.$store.getters["PRODUCT/productModelSiteConfigs"];
    },

    productOptionsOfProduct() {
      return this.$store.getters["PRODUCT_OPTION/productOptionsOfProduct"];
    },

    productOptionStatusRequest() {
      return this.$store.getters["PRODUCT_OPTION/statusRequest"];
    }
  },

  methods: {
    handleChangeProductStatus(value) {
      // 0: ngừng kinh doanh, 1: kinh doanh
      if (value === "SEO_ONLY" || value === "HIDDEN") {
        this.toggleAllTtkdStatus(0);
      }
    },

    async toggleAllTtkdStatus(value) {
      const payload = this.productOptionsOfProduct.map(i => {
        return {
          product_option_id: i.id,
          ttkd: value
        };
      });

      await this.$store.dispatch(
        "PRODUCT_OPTION/updateBusinessStatus",
        payload
      );

      await this.$store.dispatch(
        "PRODUCT_OPTION/getProductOptionsByProductId",
        this.product.id
      );
    }
  }
};
</script>
