<template>
  <v-toolbar class="modal-toolbar rounded-0" color="primary" dark height="56px">
    <v-btn dark icon @click="closeModal">
      <v-icon>mdi-close</v-icon>
    </v-btn>

    <v-toolbar-title class="text-h5 font-weight-bold pl-0 ml-1">
      Sản phẩm
    </v-toolbar-title>

    <v-toolbar-items class="align-center ml-4">
      <v-radio-group v-model="modalMode" dense hide-details row>
        <v-radio label="Thêm sản phẩm" value="create"></v-radio>
        <v-radio label="Sửa sản phẩm" value="update"></v-radio>
      </v-radio-group>
      <v-autocomplete
        v-if="modalMode === 'update'"
        :items="searchProducts"
        :search-input.sync="productSearchKey"
        class="text-body-1"
        clearable
        dense
        flat
        hide-details
        item-text="name"
        item-value="id"
        no-data-text="Không có dữ liệu"
        no-filter
        placeholder="Nhập mã, tên sản phẩm"
        return-object
        solo-inverted
        @change="getProduct($event)"
      ></v-autocomplete>
    </v-toolbar-items>

    <v-spacer></v-spacer>

    <v-toolbar-items>
      <v-btn
        v-if="!product.id"
        :loading="productStatusRequest.value === 'loading-createProduct'"
        dark
        text
        @click="createProduct"
      >
        Thêm
      </v-btn>
      <v-btn
        v-else
        :loading="productStatusRequest.value === 'loading-updateProduct'"
        dark
        text
        @click="updateProduct"
      >
        Lưu
      </v-btn>
    </v-toolbar-items>
  </v-toolbar>
</template>

<script>
import { LIST_MODEL_TYPES } from "@/core/constant";
import { toFormData } from "@vt7/utils";
import { showModalAlertError } from "@/core/composables";

export default {
  props: {
    formDataThumbnail: {
      required: true
    }
  },

  data() {
    return {
      modalMode: "create",
      productSearchKey: null
    };
  },

  computed: {
    categories() {
      return this.$store.getters["PRODUCT_TYPE/categories"];
    },

    product() {
      return this.$store.getters["PRODUCT/product"];
    },
    productModelSiteConfigs() {
      return this.$store.getters["PRODUCT/productModelSiteConfigs"];
    },
    productStatusRequest() {
      return this.$store.getters["PRODUCT/statusRequest"];
    },
    searchProducts() {
      return this.$store.getters["PRODUCT/searchProducts"];
    },
    tag() {
      return this.$store.getters["TAG/tag"];
    },
    uploadedThumbnail() {
      return this.$store.getters["PRODUCT/uploadedThumbnail"];
    },
    selectedSites() {
      return this.productModelSiteConfigs
        .filter(item => item.available)
        .map(item => item.site);
    }
  },

  watch: {
    productSearchKey(val) {
      if (val !== null && val.length > 0) {
        this.$store.dispatch("PRODUCT/searchProducts", {
          search: val
        });
      }
    }
  },

  created() {
    if (this.product.id) {
      this.modalMode = "update";
    }
  },

  methods: {
    async closeModal() {
      this.$modal.hide({ name: "modal-product" });
      this.modalMode = "create";

      // Reset product
      await this.$store.dispatch("PRODUCT/resetProduct");
      await this.$store.dispatch("PRODUCT/setProductModelSiteConfigs", []);
      await this.$store.dispatch("PRODUCT_OPTION/resetProductOptionsOfProduct");
    },

    async createProduct() {
      const routeQuery = this.$route.query;

      // Upload Thumbnail
      await this.onUploadThumbnail();

      const objSender = { ...this.product };

      objSender.thumbnail = this.uploadedThumbnail;

      // Create tags
      objSender.tags = await this.createTags(objSender.tags);
      this.product.tags = objSender.tags;
      // Map tags to id arrays
      objSender.tags = objSender.tags.map(tag => tag.id);
      // Request create
      await this.$store.dispatch("PRODUCT/createProduct", {
        data: objSender,
        routeQuery:
          this.$route.name === "goods_products"
            ? {
                filters: {
                  category_id:
                    typeof routeQuery.category_id === "string"
                      ? [parseInt(routeQuery.category_id)]
                      : typeof routeQuery.category_id === "object"
                      ? routeQuery.category_id.map(item => parseInt(item))
                      : null,
                  brand_id:
                    typeof routeQuery.brand_id === "string"
                      ? [parseInt(routeQuery.brand_id)]
                      : typeof routeQuery.brand_id === "object"
                      ? routeQuery.brand_id.map(item => parseInt(item))
                      : null,
                  ttrm:
                    typeof routeQuery.ttrm === "string"
                      ? [parseInt(routeQuery.ttrm)]
                      : typeof routeQuery.ttrm === "object"
                      ? routeQuery.ttrm.map(item => parseInt(item))
                      : [],
                  ttkd: parseInt(routeQuery.ttkd)
                },
                search: routeQuery.search || null,
                per_page: parseInt(routeQuery.limit),
                cur_page: parseInt(routeQuery.page)
              }
            : undefined
      });

      if (this.productStatusRequest.value === "success-createProduct") {
        await this.$store.dispatch("MODEL_SITE_CONFIG/updateModelSiteConfigs", {
          data: this.productModelSiteConfigs.map(item => {
            item.model_id = this.product.id;

            return item;
          }),
          modelId: this.product.id,
          modelType: LIST_MODEL_TYPES.PRODUCT
        });

        this.$toast.show({
          name: "toast-action-alert",
          payload: {
            message: "Đã thêm sản phẩm"
          }
        });
      } else if (this.productStatusRequest.value === "error-createProduct") {
        this.$modal.show({
          name: "modal-alert",
          payload: {
            title: `<span class="red--text text--accent-2">${this.productStatusRequest.message}</span>`,
            cancelBtnText: "OK"
          }
        });
      }
    },

    async createTags(tags) {
      const res = await Promise.all(
        tags.map(async tag => {
          if (typeof tag === "string") {
            await this.$store.dispatch("TAG/createTag", {
              name: tag
            });
            return this.tag;
          } else return tag;
        })
      );
      return res;
    },

    async getProduct(product) {
      await this.$store.dispatch("PRODUCT/getProductById", product.id);

      await this.$store.dispatch(
        "PRODUCT_OPTION/getProductOptionsByProductId",
        product.id
      );

      const response = await this.$store.dispatch(
        "MODEL_SITE_CONFIG/getModelSiteConfigsByModelId",
        {
          modelId: product.id,
          modelType: LIST_MODEL_TYPES.PRODUCT
        }
      );

      await this.$store.dispatch(
        "PRODUCT/setProductModelSiteConfigs",
        response
      );
    },

    // getSitesByCategoryId(categoryId) {
    //   return this.categories.find(item => item.id === categoryId).sites;
    // },

    async updateProduct() {
      const routeQuery = this.$route.query;
      const objSender = { ...this.product };

      // Check thumbnail changes
      if (objSender.thumbnail instanceof File) {
        await this.onUploadThumbnail();

        objSender.thumbnail = this.uploadedThumbnail;
      }

      // Check empty tag
      if (!Object.prototype.hasOwnProperty.call(objSender, "tags")) {
        objSender.tags = [];
      }

      // Create tags
      objSender.tags = await this.createTags(objSender.tags);
      this.product.tags = objSender.tags;

      // Map tags to id arrays
      objSender.tags = objSender.tags.map(tag => tag.id);

      // Request update

      await this.$store.dispatch("PRODUCT/updateProduct", {
        data: objSender,
        routeQuery:
          this.$route.name === "goods_products"
            ? {
                filters: {
                  category_id:
                    typeof routeQuery.category_id === "string"
                      ? [parseInt(routeQuery.category_id)]
                      : typeof routeQuery.category_id === "object"
                      ? routeQuery.category_id.map(item => parseInt(item))
                      : null,
                  brand_id:
                    typeof routeQuery.brand_id === "string"
                      ? [parseInt(routeQuery.brand_id)]
                      : typeof routeQuery.brand_id === "object"
                      ? routeQuery.brand_id.map(item => parseInt(item))
                      : null,
                  ttrm:
                    typeof routeQuery.ttrm === "string"
                      ? [parseInt(routeQuery.ttrm)]
                      : typeof routeQuery.ttrm === "object"
                      ? routeQuery.ttrm.map(item => parseInt(item))
                      : null,
                  ttkd: [parseInt(routeQuery.ttkd)],
                  listing_display_thinkpro:
                    Number(routeQuery.listing_display_thinkpro) ?? 1,
                  listing_display_goodspace:
                    Number(routeQuery.listing_display_goodspace) ?? 1
                },
                search: routeQuery.search || null,
                per_page: parseInt(routeQuery.limit),
                cur_page: parseInt(routeQuery.page)
              }
            : undefined
      });

      if (this.productStatusRequest.value === "success-updateProduct") {
        await this.$store.dispatch("MODEL_SITE_CONFIG/updateModelSiteConfigs", {
          data: this.productModelSiteConfigs,
          modelId: this.product.id,
          modelType: LIST_MODEL_TYPES.PRODUCT
        });

        // Update product option
        await this.$store.dispatch(
          "PRODUCT_OPTION/getProductOptionsByProductId",
          this.product.id
        );

        this.$toast.show({
          name: "toast-action-alert",
          payload: {
            message: "Đã lưu cập nhật"
          }
        });
      } else if (this.productStatusRequest.value === "error-updateProduct") {
        this.$modal.show({
          name: "modal-alert",
          payload: {
            title: `<span class="red--text text--accent-2">${this.productStatusRequest.message}</span>`,
            cancelBtnText: "OK"
          }
        });
      }
    },

    async uploadThumbnail(formData) {
      if (!formData) return;

      const payload = {
        formData: formData,
        name: this.product.name
        // sites: this.selectedSites.length
        //   ? this.selectedSites
        //   : this.getSitesByCategoryId(this.product.category_id)
      };

      await this.$store.dispatch("PRODUCT/uploadThumbnail", payload);
    },

    async onUploadThumbnail() {
      await this.uploadThumbnail(
        toFormData({
          file: this.product.thumbnail
        })
      );

      // Set uploaded thumbnail
      if (this.productStatusRequest.value === "success-uploadThumbnail") {
        this.product.thumbnail = this.uploadedThumbnail;
      } else if (this.productStatusRequest.value === "error-uploadThumbnail") {
        showModalAlertError(this, {
          title: "Lỗi tải lên ảnh đại diện",
          message: this.productStatusRequest.message
        });

        return;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.modal-toolbar {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2) !important;
}
</style>
